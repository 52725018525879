<template>
  <b-breadcrumb class="mb-0">
    <b-breadcrumb-item
      v-if="!folderOrProjectItem"
      to="/">
      <h2 class="mb-0">{{ $t('webmag.dashboard') }}</h2>
    </b-breadcrumb-item>
    <b-breadcrumb-item
      v-else
      :to="folderOrProjectItem.to">
      <h2
        :id="`tooltip-button-variant-${folderOrProjectItem.to}`"
        class="mb-0">
        {{ folderOrProjectItem.text | sliceStingPos30 }}
      </h2>
      <b-tooltip
        v-if="folderOrProjectItem.text.length > 30"
        :target="`tooltip-button-variant-${folderOrProjectItem.to}`"
      >
        {{ folderOrProjectItem.text }}
      </b-tooltip>
    </b-breadcrumb-item>
    <b-breadcrumb-item active v-if="pageItem && !savingData && !allSaved">
      <h2
        :id="`tooltip-button-variant-${pageItem.to}`"
        class="mb-0">
        {{ pageItem.text | sliceStingPos30 }}
      </h2>
      <b-tooltip
        v-if="pageItem.text.length > 30"
        :target="`tooltip-button-variant-${pageItem.to}`"
      >
        {{ pageItem.text }}
      </b-tooltip>
    </b-breadcrumb-item>
    <b-breadcrumb-item active v-if="pageItem && savingData && !allSaved">
      <h2 class="mb-0" style="color: #5cb85c">{{ $t('button.saving') }}</h2>
    </b-breadcrumb-item>
    <b-breadcrumb-item active v-if="pageItem && !savingData && allSaved">
      <h2 class="mb-0" style="color: #5cb85c">{{ $t('button.saved') }}</h2>
    </b-breadcrumb-item>
    <router-link
      data-page-step="4"
      v-if="!savingData && !allSaved && accessToPageSettings"
      class="settings-item mt-1 ml-2"
      :to="`${$route.path}/page-settings`">
      <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
    </router-link>
  </b-breadcrumb>
</template>

<script>
import SetAutosaveIndicatorForHeaderInCache from '@/graphQlQueries/mutations/setAutosaveIndicatorForHeaderInCache';
import SetButtonsDisabledOnAutosave from '@/graphQlQueries/mutations/setButtonsDisabledOnAutosave';
import GetAutosaveIndicatorForHeaderFromCache from '@/graphQlQueries/queries/getAutosaveIndicatorForHeaderFromCache';

export default {
  name: 'PageBreadcrumb',
  props: {
    pageItem: { type: Object },
    folderOrProjectItem: { type: Object },
    accessToPageSettings: { type: Boolean, default: false },
  },
  data: () => ({
    savingData: false,
    allSaved: false,
  }),
  computed: {
    isRootFolder() {
      return !this.$route.params.parametersOfUrl;
    },
  },
  filters: {
    sliceStingPos30(value) {
      return (value.length > 30) ? `${value.slice(0, 30)}...` : value;
    },
  },
  apollo: {
    currentPageType: {
      query: GetAutosaveIndicatorForHeaderFromCache,
      update(data) {
        this.savingData = data.autosaveIndicator.isSavingData;
        if (data.autosaveIndicator.allSaved) {
          this.savingData = false;
          this.allSaved = data.autosaveIndicator.allSaved;
          // hide the publish query
          this.$apollo.mutate({
            mutation: SetButtonsDisabledOnAutosave,
            variables: {
              isButtonDisabled: true,
            },
          }).then(() => {
            setTimeout(() => { this.resetSaveQuery(); }, 1000);
          });
        }
      },
    },
  },
  methods: {
    resetSaveQuery() {
      this.$apollo.mutate({
        mutation: SetAutosaveIndicatorForHeaderInCache,
        variables: {
          isSavingData: false,
          allSaved: false,
        },
      }).then(() => {
        // show the publish buttons
        this.$apollo.mutate({
          mutation: SetButtonsDisabledOnAutosave,
          variables: {
            isButtonDisabled: false,
          },
        });
      });
      this.savingData = false;
      this.allSaved = false;
    },
  },
};
</script>

<style scoped>

</style>
