import gql from 'graphql-tag';

export default gql`
mutation(
  $isSavingData: Boolean!,
  $allSaved: Boolean!,
) {
  setAutosaveIndicatorForHeader(
    isSavingData: $isSavingData,
    allSaved: $allSaved,
  ) @client
}
`;
