var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-breadcrumb",
    { staticClass: "mb-0" },
    [
      !_vm.folderOrProjectItem
        ? _c("b-breadcrumb-item", { attrs: { to: "/" } }, [
            _c("h2", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.$t("webmag.dashboard")))
            ])
          ])
        : _c(
            "b-breadcrumb-item",
            { attrs: { to: _vm.folderOrProjectItem.to } },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-0",
                  attrs: {
                    id: `tooltip-button-variant-${_vm.folderOrProjectItem.to}`
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("sliceStingPos30")(_vm.folderOrProjectItem.text)
                      ) +
                      " "
                  )
                ]
              ),
              _vm.folderOrProjectItem.text.length > 30
                ? _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: `tooltip-button-variant-${_vm.folderOrProjectItem.to}`
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.folderOrProjectItem.text) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
      _vm.pageItem && !_vm.savingData && !_vm.allSaved
        ? _c(
            "b-breadcrumb-item",
            { attrs: { active: "" } },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-0",
                  attrs: { id: `tooltip-button-variant-${_vm.pageItem.to}` }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("sliceStingPos30")(_vm.pageItem.text)) +
                      " "
                  )
                ]
              ),
              _vm.pageItem.text.length > 30
                ? _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: `tooltip-button-variant-${_vm.pageItem.to}`
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.pageItem.text) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.pageItem && _vm.savingData && !_vm.allSaved
        ? _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _c(
              "h2",
              { staticClass: "mb-0", staticStyle: { color: "#5cb85c" } },
              [_vm._v(_vm._s(_vm.$t("button.saving")))]
            )
          ])
        : _vm._e(),
      _vm.pageItem && !_vm.savingData && _vm.allSaved
        ? _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _c(
              "h2",
              { staticClass: "mb-0", staticStyle: { color: "#5cb85c" } },
              [_vm._v(_vm._s(_vm.$t("button.saved")))]
            )
          ])
        : _vm._e(),
      !_vm.savingData && !_vm.allSaved && _vm.accessToPageSettings
        ? _c(
            "router-link",
            {
              staticClass: "settings-item mt-1 ml-2",
              attrs: {
                "data-page-step": "4",
                to: `${_vm.$route.path}/page-settings`
              }
            },
            [
              _c("b-icon", {
                attrs: { icon: "gear-fill", "aria-hidden": "true" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }